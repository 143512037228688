// tslint:disable:no-shadowed-variable

import router from "@/router";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";
import { Taxonomy } from "@/data/taxonomy/taxonomy";

// State.
const state = {
	initialized: false,
	selectedEdition: null,
	defaultEdition: "2021",
	activeEditions: ["2019", "2021"],
	content: null
};

// Getters.
// You can think of them as computed properties for stores. Like computed properties, a getter's result is cached
// based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
const getters = {
	getSelectedEdition: (state: any): Taxonomy => {
		return state.selectedEdition;
	},
	getCurrentEdition: (state: any): Taxonomy => {
		return (state.content || []).find((edition: Taxonomy) => edition.name === state.selectedEdition);
	},
	getAvailableEditions: (state: any): string[] => {
		return (state.activeEditions || [])
			.map((edition: any) => edition.name)
			.filter((edition: string) => (state.activeEditions.length ? state.activeEditions.includes(edition) : true));
	},
	hasInitialized: (state: any): boolean => {
		return state.initialized;
	}
};

// Mutations.
// The only way to actually change state in a Vuex store is by committing a mutation.
const mutations = {
	setSelectedEdition: (state: any, edition: string): void => {
		state.selectedEdition = edition;
	},
	finishedInitialization: (state: any): void => {
		state.initialized = true;
	},
	setContent: (state: any, content: Taxonomy[]): void => {
		state.content = content;
	},
	setDefaultEdition: (state: any, edition: string): void => {
		state.defaultEdition = edition;
	},
	setActiveEditions: (state: any, editions: string[]): void => {
		state.activeEditions = [...editions];
	}
};

// Actions are similar to mutations, the differences being that:
// · Instead of mutating the state, actions commit mutations.
// · Actions can contain arbitrary asynchronous operations.
const actions = {
	initModule: async ({ state, commit, dispatch }: any): Promise<void> => {
		// Get content
		await Promise.all([dispatch("getEditions")]);

		// Set edition
		const editionQuery = router.currentRoute.query.edition || state.defaultEdition;
		dispatch("changeEdition", editionQuery);

		// Finish
		commit("finishedInitialization");
	},

	getEditions: async ({ state, commit, dispatch }: any): Promise<void> => {
		const content = await new TaxonomyModel("edition").getList();
		commit("setContent", content);
		return Promise.resolve();
	},

	changeEdition: ({ state, commit, dispatch }: any, edition: string): void => {
		
		// Is different than current
		if (edition !== state.selectedEdition) {
			// Set an edition that exists
			const editionToApply = getters.getAvailableEditions(state).includes(edition)
			? edition
			: state.defaultEdition;
			
			commit("setSelectedEdition", editionToApply);
			dispatch("changeRouter", editionToApply);
		}
	},
	changeRouter: ({ state }: any, edition: string): void => {
		const editionQuery = router.currentRoute.query.edition;

		// Is different than current query string
		if (edition && edition !== editionQuery) {
			// Evaluate if query should be added or removed
			const r = router as any;
			const query : any = r.history.current.query;
			router.push({ query: { edition, ... query } });
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
