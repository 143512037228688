import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, State, Mutation } from "vuex-class";

import instance from "@/main";
import { OptionModel } from "@/data/option/option.model";
import { EventModel } from "@/data/event/event.model";
import { ArticleModel } from "@/data/article/article.model";

@Component({
	name: "HomePage"
})
export default class HomePageComponent extends Vue {
	private content: any = null;
	private contentIsReady: boolean = false;
	private events: any = null;
	private eventsIsReady: boolean = false;
	private separatedEvents: any = [];
	private separatedTypes: any = [];
	private news: any = null;
	private newsIsReady: boolean = false;
	private url: string = window.location.href;
	
	private titleIsVisible: boolean = true;

	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@State("i18n") private i18nState!: any;
	@Mutation("state/setLoading") private setLoading;
	
	@Getter("core/getContent") private cores!: any;
	@Getter("core/hasInitialized") private coreHasInitialized!: any;

	@Getter("core/getContent") private eventTypes!: any;
	@Getter("core/hasInitialized") private eventTypesHasInitialized!: any;

	private mounted() {
		this.getContent();

		// window.addEventListener("scroll", this.evalScroll)
	}
	
	// private evalScroll(){
	// 	// const title = document.getElementById("home-title") as HTMLElement;
	// 	// const header = document.querySelector("#project-header") as HTMLElement;
	// 	const body = document.body
	// 	const html = document.documentElement;

	// 	const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

	// 	const footer = document.getElementById("footer") as HTMLElement;
	// 	// console.log("TITLE:", title)
	// 	// console.log("FOOTER:", footer)
	// 	console.log("FOOTER HEIGHT:", footer.offsetHeight)
	// 	console.log("WINDOW PAGEYOFFSET", window.scrollY)
	// 	console.log("HEIGHT - FOOTER.OFFSETHEIGHT", height - footer.offsetHeight)
	// 	if (window.scrollY < height - footer.offsetHeight) {
	// 		this.titleIsVisible = true;
	// 	} else {
	// 		this.titleIsVisible = false;
	// 	}
	// }

	@Watch("cores")
	@Watch("eventTypes")
	@Watch("currentLang")
	private async getContent() {
		if (this.i18nState.currentLang === this.$route.params.lang) {
			// this.content = await new OptionModel("home-page-options").getEntry({ related_recursive_calls: 1 });
			this.separatedEvents = [];
			this.setLoading(true);
			this.eventsIsReady = false;
			this.events = await new EventModel().getList({ per_page: 50 });
		}
		if (this.coreHasInitialized && this.cores && this.cores.content.length) {
			this.separateEvents();
			this.eventsIsReady = true;
			this.setLoading(false);
		}
		if (this.eventTypesHasInitialized && this.eventTypes && this.eventTypes.content.length) {
			this.separateTypes();
			this.eventsIsReady = true;
			this.setLoading(false);
		}
	}

	private separateEvents() {
		const tempEvents = [];
		for (const event of this.events.content) {
			if ( event.cores ) {
				const index = tempEvents.findIndex(e => e.id === event.cores[0]);
				if( index >= 0 ) {
					tempEvents[index].events.push(event)
				} else {
					const coreIndex = this.cores.content.findIndex(e => e.id === event.cores[0] )
					if( coreIndex >= 0) {
						const core = this.cores.content[coreIndex];
						tempEvents.push({
							name: core.name,
							id: core.id,
							events: [ event ]
						});
					}
				}
			}
		}
		this.separatedEvents = tempEvents;
	}

	private separateTypes() {
		const tempTypes = [];
		for (const event of this.events.content) {
			if ( event.eventTypes ) {
				const index = tempTypes.findIndex(e => e.id === event.eventTypes[0]);
				if( index >= 0 ) {
					tempTypes[index].events.push(event)
				} else {
					const eventTypeIndex = this.eventTypes.content.findIndex(e => e.id === event.eventTypes[0] )
					if( eventTypeIndex >= 0) {
						const eventType = this.eventTypes.content[eventTypeIndex];
						tempTypes.push({
							name: eventType.name,
							id: eventType.id,
							events: [ event ]
						});
					}
				}
			}
		}
		this.separatedTypes = tempTypes;
	}
}
