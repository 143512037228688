import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import i18n from "@/i18n";

import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "PersonBlock"
})
export default class PersonBlock extends Vue {
	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];

	@Getter("taxonomy/getContent") private taxonomies!: any[];

	@Prop() private content: any;
	@Prop() private index!: number;
}
