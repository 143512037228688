import get from "lodash/get";

export class File {
	private id?: string;
	private title?: string;
	private description?: string;
	private caption?: string;
	private alt?: string;
	private url: string;
	private mimeType: string;

	constructor(object: any, wordpress?: boolean) {
		this.id = wordpress ? object.ID : object.id;
		this.title = object.title;
		this.description = object.description;
		this.caption = object.caption;
		this.alt = object.alt;
		this.url = object.url;
		this.mimeType = object.mime_type;
	}
}
