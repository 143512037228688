import get from "lodash/get";
import has from "lodash/has";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { ImageAcf } from "@/data/image_acf";
import { Route } from "@/data/route/route";

import { LocationModel } from "@/data/location/location.model";

export class RouteModel {
	private namespace!: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.modelName = "routes";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Route> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			)).data;
			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			));
			
			const processedList = data.data.map( entry => {
				return this.processEntry(entry)
			})
			
			return {
				content: processedList,
				total: Number(data.headers["x-wp-total"]),
				totalPages: Number(data.headers["x-wp-totalpages"])
			};
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any, processRelations: boolean = true): Route {
		// console.log("LocationModel :: processEntry() :: data -> ", data )

		// Null check
		if (!data) {
			return null;
		}

		let processedEditions;
		let processedTransports;
		if(data.related_taxonomies) {
			if( data.edition && data.edition.length ) {
				processedEditions = data.edition.map(id => {
					if (id && data.related_taxonomies) {
						return data.related_taxonomies[id]
					}
				})
			}
			if( data.transport && data.transport.length ) {
				processedTransports = data.transport.map(id => {
					if (id && data.related_taxonomies) {
						return data.related_taxonomies[id]
					}
				})
			}
		}

		let processedLocations;
		if(data.related_ids) {
			if( data.acf.locations ) {
				processedLocations = data.acf.locations.map(id => {
					if (data.related_ids && data.related_ids[id]) {
						return new LocationModel().processEntry(data.related_ids[id])
					}
				})
			}
		}


		const content: Route = {
			id: data.id,
			title: get(data, "title.rendered"),
			slug: data.slug,
			status: data.status,
			
			editions: processedEditions,
			transports: processedTransports,
			
			events: data.acf.events || [],
			locations: processedLocations || []
		};

		return content;
	}
}
