import Vue from "vue";
import Router from "vue-router";

import instance from "@/main";
import store from "@/store";
import HomePage from "@/components/pages/home/home.page.vue";
import NewsPage from "@/components/pages/news/news.page.vue";
import ArticlePage from "@/components/pages/article/article.page.vue";
import EventPage from "@/components/pages/event/event.page.vue";
import EventsPage from "@/components/pages/events/events.page.vue";
import MapPage from "@/components/pages/map/map.page.vue";
import PartnersPage from "@/components/pages/partners/partners.page.vue";
import PersonsPage from "@/components/pages/persons/persons.page.vue";
import PressPage from "@/components/pages/press/press.page.vue";
import SamplePage from "@/components/pages/sample/sample.page.vue";
import { component } from "vue/types/umd";

Vue.use(Router);

const router = new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/en"
		},
		{
			path: "/:lang",
			name: "HomePage",
			component: HomePage,
		},
		{
			path: "/:lang/news",
			name: "NewsPage",
			component: NewsPage
		},
		{
			path: "/:lang/news/:slug",
			name: "ArticlePage",
			component: ArticlePage
		},
		{
			path: "/:lang/events",
			name: "EventsPage",
			component: EventsPage
		},
		{
			path: "/:lang/events/:slug",
			name: "EventPage",
			component: EventPage
		},
		{
			path: "/:lang/persons",
			name: "PersonsPage",
			component: PersonsPage
		},
		{
			path: "/:lang/map",
			name: "MapPage",
			component: MapPage
		},
		{
			path: "/:lang/partners",
			name: "PartnersPage",
			component: PartnersPage
		},
		{
			path: "/:lang/press",
			name: "PressPage",
			component: PressPage
		},
		{
			path: "/:lang/page/:slug",
			name: "SamplePage",
			component: SamplePage
		}
	],
	scrollBehavior(to, from, savedPosition) {
		// Scroll to top if:
		// (1) Different pages; or
		// (2) Same page without fragments (because they will trigger a programatic scroll to the element)
		if (from.name !== to.name || (from.name === to.name && (!from.hash && !to.hash))) {
			return { x: 0, y: 0 };
			// Scroll to `savedPosition` on `PAGE_IS_READY` trigger
		} else if (savedPosition) {
			return new Promise((resolve, reject) => {
				instance.$on("PAGE_IS_READY", (payload: boolean) => {
					payload ? resolve(savedPosition) : reject(savedPosition);
				});
			});
		}
	}
});

// router.beforeEach(async (to, from, next) => {
// 	// Merge query parameters
// 	if (!hasQueryParams(to) && hasQueryParams(from)) {
// 		next({ name: to.name, params: to.params, query: { ...from.query, ...to.query }, hash: to.hash });
// 	} else {
// 		next();
// 	}

// 	// Scroll to element if fragment is present in URL
// 	if (to.hash) {
// 		const interval = setInterval(() => {
// 			const el = document.querySelector(to.hash);

// 			// Stop loop after 5 seconds
// 			setTimeout(() => {
// 				// tslint:disable-next-line:no-unused-expression
// 				interval && clearInterval(interval);
// 			}, 1000 * 5);

// 			if (el) {
// 				// Stop loop
// 				clearInterval(interval);

// 				// Scroll to element
// 				el.scrollIntoView({ behavior: "smooth" });

// 				// Remove fragment from URL if user scrolls
// 				// so that the element it's outside of viewport
// 				const observer = new IntersectionObserver(
// 					entries => {
// 						entries.forEach(entry => {
// 							if (entry.isIntersecting) {
// 								alreadyObserved = true;
// 							} else if (alreadyObserved) {
// 								router.replace({ hash: "" });
// 								observer.unobserve(el);
// 							}
// 						});
// 					},
// 					{
// 						threshold: 0.75
// 					}
// 				);

// 				let alreadyObserved = false;
// 				observer.observe(el);
// 			}
// 		}, 100);
// 	}
// });

function hasQueryParams(route: any) {
	return !!Object.keys(route.query).length;
}

export default router;
