import Vue from "vue";
import VueSanitize from "vue-sanitize";
import vueHeadful from "vue-headful";


import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import store from "./store";
import "./registerServiceWorker";
import HomePageComponent from "@/components/pages/home/home.page.vue";
import moment from "moment";
// Blocks
import LogoBlockComponent from "@/components/blocks/logo/logo.block.vue";
import HeaderBlockComponent from "@/components/blocks/header/header.block.vue";
import FooterBlockComponent from "@/components/blocks/footer/footer.block.vue";
import SidebarBlockComponent from "@/components/blocks/sidebar/sidebar.block.vue";
import ImageBlockComponent from "@/components/blocks/image/image.block.vue";
import SlideshowBlockComponent from "@/components/blocks/slideshow/slideshow.block.vue";
import LoadingBlockComponent from "@/components/blocks/loading/loading.block.vue";
import FlexibleContentBlockComponent from "@/components/blocks/flexible-content/flexible-content.block.vue";

// Custom Blocks
import ConstructorBlockComponent from "@/components/blocks/constructor/constructor.block.vue";
import CardBlockComponent from "@/components/blocks/card/card.block.vue";
import CuratorBlockComponent from "@/components/blocks/curator/curator.block.vue";
import ListBlockComponent from "@/components/blocks/list/list.block.vue";
import PartnerBlockComponent from "@/components/blocks/partner/partner.block.vue";
import CloudBlockComponent from "@/components/blocks/cloud/cloud.block.vue";

import MatchMediaFilter from "@/filters/match-media.filter";
import SortByFilter from "@/filters/sort-by.filter";
import CountLinesFilter from "@/filters/count-lines.filter";

Vue.config.productionTip = false;

// mapbox TS
import VueMapboxTs from "vue-mapbox-ts-legacy"
Vue.use(VueMapboxTs)

// Pages
Vue.component("home-page", HomePageComponent);

// Blocks
Vue.component("logo-block", LogoBlockComponent);
Vue.component("header-block", HeaderBlockComponent);
Vue.component("footer-block", FooterBlockComponent);
Vue.component("sidebar-block", SidebarBlockComponent);
Vue.component("image-block", ImageBlockComponent);
Vue.component("slideshow-block", SlideshowBlockComponent);
Vue.component("loading-block", LoadingBlockComponent);
Vue.component("flexible-content-block", FlexibleContentBlockComponent);

// Custom Blocks
Vue.component("constructor-block", ConstructorBlockComponent);
Vue.component("card-block", CardBlockComponent);
Vue.component("curator-block", CuratorBlockComponent);
Vue.component("list-block", ListBlockComponent);
Vue.component("partner-block", PartnerBlockComponent);
Vue.component("cloud-block", CloudBlockComponent);

// Filters.
Vue.filter("matchMedia", MatchMediaFilter);
Vue.filter("sortBy", SortByFilter);
Vue.filter("countLines", CountLinesFilter);
// tslint:disable-next-line
Vue.prototype.moment = moment
// Vue.use(require("vue-moment"));
// tslint:disable-next-line
require('moment/locale/pt')

// Plugins.
Vue.component("vue-headful", vueHeadful);
Vue.use(VueSanitize, {
	allowedTags: [
		"h1",
		"h2",
		"h3",
		"h4",
		"h5",
		"h6",
		"b",
		"i",
		"em",
		"strong",
		"a",
		"p",
		"ul",
		"ol",
		"li",
		"blockquote",
		"pre",
		"code",
		"ins",
		"img"
	],
	allowedAttributes: {
		"*": ["style"],
		a: ["href"],
		insc: ["datetime"],
		del: ["datetime"],
		img: ["src"]
	},
	allowedStyles: {
		"*": { "text-align": [/^left$/, /^right$/, /^center$/] }
	}
});

// The Vue instance.
const instance = new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount("#app");

declare module "vue/types/vue" {
	interface Vue {
		$moment: any;
		moment: any;
	}
}

// Flag page state in `<html>`
instance.$on("PAGE_IS_READY", (isReady: boolean) => {
	document.documentElement.toggleAttribute("data-page-is-ready", isReady);
})

// Initialization of stores modules.
store.dispatch("i18n/initModule");
store.dispatch("edition/initModule");
store.dispatch("menu/initModule");
store.dispatch("core/initModule");
store.dispatch("location/initModule");
store.dispatch("eventType/initModule");

export default instance;
