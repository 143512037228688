import get from "lodash/get";
import has from "lodash/has";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { ImageAcf } from "@/data/image_acf";
import { Person } from "@/data/person/person";
import { castArray } from "lodash";

export class PersonModel {
	private namespace!: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.modelName = "persons";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Person> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			)).data;
			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			));
			
			const processedList = data.data.map( entry => {
				return this.processEntry(entry)
			})
			
			return {
				content: processedList,
				total: Number(data.headers["x-wp-total"]),
				totalPages: Number(data.headers["x-wp-totalpages"])
			};
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any, processRelations: boolean = true): Person {
		// console.log("PersonModel :: processEntry() :: data -> ", data )

		// Null check
		if (!data) {
			return null;
		}

		let processedPersonTypes;
		let processedEditions;
		if (data.related_taxonomies) {
			if ( data.person_type && data.person_type.length) {
				processedPersonTypes = data.person_type.map(id => {
					if (id && data.related_taxonomies) {
						return data.related_taxonomies[id]
					}
				})
			}
			if( data.edition && data.edition.length ) {
				processedEditions = data.edition.map(id => {
					if (id && data.related_taxonomies) {
						return data.related_taxonomies[id]
					}
				})
			}
		}

		const content: Person = {
			id: data.id,
			title: get(data, "title.rendered"),
			slug: data.slug,
			
			editions: processedEditions,
			personType: processedPersonTypes,
			image: data.acf.image ? new ImageAcf(data.acf.image) : null,
			shortBiography: data.acf.short_biography,
			links: data.acf.links,
		};

		return content;
	}
}
