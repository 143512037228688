import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, State } from "vuex-class";

import instance from "@/main";
import { EventModel } from "@/data/event/event.model";
import { scrollTo } from "@/scrollTo";

import { addFilter, getFilterObject, getActiveFilter, evalFilter } from "@/data/route";

@Component({
	name: "EventPage"
})
export default class EventPageComponent extends Vue {
	private contentIsReady: boolean = false;
	private waitingForContent: boolean = false;
	private content: any = null;
	
	private sectionIsActive: string = "";
	private sidebarIsVisible: boolean = true;

	private url: string = window.location.href;

	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@State("i18n") private i18nState!: any;

	private mounted() {
		this.getContent();
		if (this.$route.query.page) {
			this.$router.push({ query: { page: undefined } })
		}
		window.addEventListener("scroll", () => {
			this.checkSidebarVisibility();
			this.checkPosition();
		})
	}

	@Watch("currentLang")
	@Watch("$route.params.slug")
	@Watch("i18nState.loadedLangs")
	private async getContent() {
		if (this.i18nState.currentLang === this.$route.params.lang) {
			const slug = this.$route.params.slug;
			this.contentIsReady = false;
			this.content = await new EventModel().getEntry({slug, related_recursive_calls: 1});
			instance.$emit("PAGE_IS_READY", true);
			this.contentIsReady = true;
		}
	}

	private callScrollTo(id, offset, offsetType) {
		scrollTo(id, offset, offsetType);
	}
	
	private checkPosition() {
		const sidebar = document.querySelector("#sections-nav");
		const sections = document.getElementsByClassName("section");

		if (sidebar) {
			const amountScrolled = window.scrollY + sidebar.getBoundingClientRect().top;
			for (const sec of sections) {
				const section = sec as HTMLElement;
				if ( amountScrolled > section.offsetTop ) {
					this.sectionIsActive = section.id;
				}
			}
		}
	}

	private checkSidebarVisibility() {
		const sidebar = document.getElementById("sections-nav") as HTMLElement;
		// const header = document.querySelector("#project-header") as HTMLElement;

		const overflowElements = document.getElementsByClassName("sidebar-overflow") as HTMLCollection;

		
		if (sidebar) {
			const offset = window.scrollY + sidebar.getBoundingClientRect().top;
			this.sidebarIsVisible = true;
			
			for (const el of overflowElements) {
				const element = el as HTMLElement;
				if (offset + sidebar.offsetHeight > element.offsetTop && offset < element.offsetTop + element.offsetHeight ) {
					this.sidebarIsVisible = false;
				}
			}
		}
	}
}
