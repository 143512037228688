import axios, { AxiosInstance, AxiosResponse } from "axios";
import { Query } from "@/data/query";

export class WpApi {
	private connection: AxiosInstance;

	constructor() {
		this.connection = axios.create({
			baseURL: process.env.API_ENDPOINT,
			headers: {
				...this.authHeader
			}
		});
	}

	/**
	 * Returns a specific entry.
	 */
	public async read(model: string, query?: Query): Promise<any> {
		const connection = this.connection;

		// Perform action and wait for response
		const data = await connection.get(model, {
			params: { ...query }
		});

		return data;
	}

	/**
	 * Updates an entry by it's ID or slug.
	 */
	public async update(model: string, query: Query, content: any): Promise<any> {
		const connection = this.connection;

		// Identifier check
		if (!query.id && !query.slug) {
			throw Error(`WpApi :: Can only update an '${model}' entry by ID or slug. Aborting.`);
		}

		// Perform action and wait for response
		const data = await connection.post(model, content, {
			params: { ...query }
		});

		return data;
	}

	/**
	 * Creates an entry.
	 */
	public async create(model: string, content: any): Promise<any> {
		const connection = this.connection;

		// Perform action and wait for response
		const data = await connection.post(model, content);

		return data;
	}

	/**
	 * Deletes an entry by
	 */
	public async delete(model: string, query: Query): Promise<any> {
		const connection = this.connection;

		// Identifier check
		if (!query.id && !query.slug) {
			throw Error(`WpApi :: Can only delete an '${model}' entry by ID or slug. Aborting.`);
		}

		// Perform action and wait for response
		const data = await connection.delete(model);

		return data;
	}

	/**
	 * Returns `Authorization` header to authenticate with WordPress.
	 * Will only be used if the appropriate environment variables are defined.
	 */
	get authHeader(): { Authorization: string } {
		if (process.env.API_ENDPOINT) {
			// Compose `Authorization` header
			try {
				let key: string;

				// Evaluate what type of tokens to use
				if (process.env.API_JWT_TOKEN) {
					// JWT Tokens
					const token = process.env.JWT_TOKEN;
					key = `Bearer ${token}`;
				} else if (process.env.API_AUTH_USER && process.env.API_AUTH_PASS) {
					// Basic Authorization
					const token = btoa(`${process.env.API_AUTH_USER}:${process.env.API_AUTH_PASS}`);
					key = `Basic ${token}`;
				}

				// Return header
				if (key) {
					return {
						Authorization: key
					};
				}
			} catch (error) {
				throw Error(`WpApi :: Couldn't compose \`Authorization \` header. Error: ${error}`);
			}
		} else {
			throw Error("WpApi :: `API_ENDPOINT is not defined. Aborting.");
		}
	}
}
