
import { Vue, Component, Watch } from "vue-property-decorator";

import store from "@/store";
import firebase from "firebase";

import { getCookie, setCookie } from "@/data/cookie";

@Component({
	name: "App"
})
export default class AppComponent extends Vue {
	private modalIsOpen : boolean = true;
	private hasAnalytics : boolean = false;

	// Watch for `lang` param changes to change language and get edition content on that language
	@Watch("$route.params.lang")
	private async onRouterLangParamChange(): Promise<void> {
		await store.dispatch("i18n/changeLanguage", this.$route.params.lang);
	}


	private mounted() {
		if(getCookie("analytics") && getCookie("analytics") === "false") {
			this.modalIsOpen = false;
		}
		if (getCookie("analytics") && getCookie("analytics") === "true") {
			this.acceptAnalytics();
			this.modalIsOpen = false;
		}
	}

	private refuseAnalytics() {
		setCookie("analytics", "false", 30);
		this.modalIsOpen = false;
	}

	private acceptAnalytics() {
		// Your web app's Firebase configuration
		// For Firebase JS SDK v7.20.0 and later, measurementId is optional
		const firebaseConfig = {
			apiKey: "AIzaSyB8Zt0dLq_SdiPgPT4diUEZtTjwefgZKjo",
			authDomain: "porto-design-biennale-dcfde.firebaseapp.com",
			databaseURL: "https://porto-design-biennale-dcfde.firebaseio.com",
			projectId: "porto-design-biennale-dcfde",
			storageBucket: "porto-design-biennale-dcfde.appspot.com",
			messagingSenderId: "744006077556",
			appId: "1:744006077556:web:c2c77dae6c12f822959521",
			measurementId: "G-XHFCP0S53C"
		};
		// Initialize Firebase
		firebase.initializeApp(firebaseConfig);
		firebase.analytics();
		setCookie("analytics", "true", 120);
		this.modalIsOpen = false;
	}

}

