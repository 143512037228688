import get from "lodash/get";
import has from "lodash/has";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { ImageAcf } from "@/data/image_acf";
import { Press } from "@/data/press/press";

export class PressModel {
	private namespace!: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.modelName = "press";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Press> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			)).data;
			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			));
			
			const processedList = data.data.map( entry => {
				return this.processEntry(entry)
			})
			
			return {
				content: processedList,
				total: Number(data.headers["x-wp-total"]),
				totalPages: Number(data.headers["x-wp-totalpages"])
			};
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any, processRelations: boolean = true): Press {
		console.log("PressModel :: processEntry() :: data -> ", data )

		// Null check
		if (!data) {
			return null;
		}

		let pressTypes;
		pressTypes = data.press_type.map(pressType => {
			if (pressType && data.related_taxonomies[pressType]) {
				pressType = data.related_taxonomies[pressType]
			}
			return pressType;
		})

		const content: Press = {
			id: data.id,
			title: get(data, "title.rendered"),
			slug: data.slug,
			date: data.acf.date ? data.acf.date : data.date,

			pressTypes,
			fileType: data.acf.file_type,
			file: data.acf.file,
			url: data.acf.url,
		};

		return content;
	}
}
