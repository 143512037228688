import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

import instance from "@/main";
import { LocationModel } from "@/data/location/location.model";
import { RouteModel } from "@/data/route/route.model";

import { addFilter, getFilterObject, getActiveFilter, evalFilter } from "@/data/route";

import mapboxgl from "mapbox-gl";

@Component({
	name: "MapPageComponent"
})
export default class MapPageComponent extends Vue {
	private contentIsReady: boolean = false;
	private waitingForContent: boolean = false;
	private locations: any = null;
	private routes: any = null;
	private showEvents: boolean = false;

	private url: string = window.location.href;

	private accessToken : string = "pk.eyJ1IjoicmFmYWVsLWdvbmNhbHZlcyIsImEiOiJja2czcGU0emQwY3k2MnhvNXBwd2VpbW1oIn0.eo4d9f7H6n8dsWOh7PnQyg";
	private map : any = null;
	
	private instructions : any = null;
	private showRoute : boolean = false;

	private colors : any = {
		"#23E84E": "mapbox://styles/rafael-goncalves/cko8ojo0p552l17qnpjavj6p8",
		"#EC11FF": "mapbox://styles/rafael-goncalves/cko8ok9i33j8y17nxktkyc9cs",
		"#1185FF": "mapbox://styles/rafael-goncalves/cko8o6yq93iwg17nxwnqxmxiz",
		"#FF5823": "mapbox://styles/rafael-goncalves/cko8okkle553g17qn9o7lyszl",
		"#8c21FF": "mapbox://styles/rafael-goncalves/cko8o78qu4nrw17qh8434niap",
		"#FF001F": "mapbox://styles/rafael-goncalves/cko8o7f6o5asl18n5xe8kixkf",
		"#FFD61F": "mapbox://styles/rafael-goncalves/cko8o7jfk54qa17qnn7pf72sn",
		"#4FD1FA": "mapbox://styles/rafael-goncalves/cko8o7w5t3ixh17nxumwcgow9",
		"#2CEDCA": "mapbox://styles/rafael-goncalves/cko8o8556288e17quqj2hl55x",
	};

	@Getter("i18n/getCurrentLang") private currentLang!: string;

	private mounted() {
		this.getContent();
		setTimeout(() => {
			this.initMap();
		}, 200);
	}

	@Watch("currentLang")
	private async getContent() {
		this.locations = await new LocationModel().getList({ per_page: 100, related_recursive_calls: 1 });
		this.routes = await new RouteModel().getList({related_recursive_calls: 1});
		this.contentIsReady = true;
		this.setMarkers();
		instance.$emit("PAGE_IS_READY", true);

		setInterval(() => {
			const online = document.getElementById("online-cloud")
			if(online) {
				const randomX = Math.floor(Math.random() * (window.innerWidth - online.offsetWidth))
				online.style.left = randomX + "px";
			}
		}, 20000);
	}

	@Watch("$route.query")
	private async getMoreContent() {
		const partnerType = this.$route.query.partner_type;
		this.locations = await new LocationModel().getList();
		this.routes = await new RouteModel().getList({ related_recursive_calls: 1 });
	}

	private initMap() {
		const body = document.querySelector("body");
		const randomColor = body.getAttribute("data-random-color")
		
		this.map = new mapboxgl.Map({
			accessToken: this.accessToken,
			container: "mapContainer",
			style: this.colors[randomColor],
			center: [-8.6392091, 41.1572279],
			zoom: 12,
			minZoom: 11,
		});
	}

	@Watch("route.query.location_type")
	private setMarkers() {
		for (const location of this.locations.content) {
			// const location = this.locations.content[i];

			let eventsHTML = "";
			if (location.relatedEvents && location.relatedEvents.length) {
				for (const event of location.relatedEvents) {
					const link = "/" + this.currentLang + "/events/" + event.slug;
					if(event.dateStart) {
						eventsHTML += `
							<h3 class="map__popup--event">
								<time class="type--caption map__popup--event-time">${this.moment(event.dateStart).format("DD.MM")} — ${this.moment(event.dateEnd).format("DD.MM")}</time>
								<a class="type--body type--rounded link" href="${link}">${event.title}</a>
							</h3>
						`;
					} else {
						eventsHTML += `
							<h3 class="map__popup--event">
								<div class="map__popup--event-time"></div>
								<a class="type--body type--rounded link" href="${link}">${event.title}</a>
							</h3>
						`;
					}
				}
			}

			// Pop-up
			const popupHtml = `
				<h2 class="type--caption type--rounded">${location.title}<h2>
				${eventsHTML}
			`;

			const markerHTML = `
				<svg class="marker-svg" data-name="cloud-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
					<circle cx="16" cy="16" r="14"/>
				</svg>
			`;

			// <h3 class="marker-number type--caption">${i+1}</h3>

			const m = document.createElement("div");
			m.innerHTML = markerHTML;

			// Marker
			if (location.map) {
				const marker = new mapboxgl.Marker(m)
				.setLngLat([location.map.lng, location.map.lat])
				.setPopup(new mapboxgl.Popup().setHTML(popupHtml))
				.addTo(this.map);
			}
		}
		
	}

	private async getDirections(route : any, index : any) {
		const routeButton = document.getElementById("route_" + index)

		// if(this.showRoute){
			routeButton.classList.add("visible-route")

			let coordinates = "";
			for (let i = 0; i < route.locations.length; i++) {
				const location = route.locations[i];
				if (location) {
					coordinates = coordinates + location.map.lng + "," + location.map.lat;
					coordinates = i < route.locations.length - 1 ? coordinates + ";" : coordinates;
				}
			}

			const directionsApiUrl = `https://api.mapbox.com/directions/v5/mapbox/walking/${coordinates}?alternatives=true&geometries=geojson&steps=true&access_token=pk.eyJ1IjoicmFmYWVsLWdvbmNhbHZlcyIsImEiOiJja2czcGU0emQwY3k2MnhvNXBwd2VpbW1oIn0.eo4d9f7H6n8dsWOh7PnQyg`;
			const response = await fetch(directionsApiUrl).then(r => r.json() );
			
			const body = document.querySelector("body");
			const randomColor = body.getAttribute("data-random-color")
			// Set GeoJson Line
			const canvas = this.map.getCanvasContainer();
			const data = response.routes[0];

			if (this.map.getLayer("route") !== undefined) {
				this.map.removeLayer("route").removeSource("route");
				const routeButtons = document.getElementsByClassName("route__locations")
				for (const button of routeButtons) {
					button.classList.remove("visible-route")
				}
				routeButton.classList.add("visible-route")
			}

			this.map.addSource("route", {
				"type": "geojson",
				"data": {
					"type": "Feature",
					"properties": {},
					"geometry": {
						"type": "LineString",
						"coordinates": data.geometry.coordinates
					}
				}
			});
			this.map.addLayer({
				"id": "route",
				"type": "line",
				"source": "route",
				"layout": {
					"line-join": "round",
					"line-cap": "round"
				},
				"paint": {
					"line-color": "#000",
					"line-width": 4
				}
			});

			// Set Instructions
			const steps = data.legs[0].steps;
			this.instructions = [];
			for (const step of steps) {
				this.instructions.push(step.maneuver.instruction);
			}
		// } 
		// else if(!this.showRoute){
		// 	this.map.removeLayer("route").removeSource("route");
		// 	const routeButtons = document.getElementsByClassName("route__locations")
		// 	for (const button of routeButtons) {
		// 		button.classList.remove("visible-route")
		// 	}
		// }
	}

	private async clearDirections(){
		this.map.removeLayer("route").removeSource("route");
		const routeButtons = document.getElementsByClassName("route__locations")
		for (const button of routeButtons) {
			button.classList.remove("visible-route")
		}
	}

	private callEvalFilter(param: string, value: any) {
		return evalFilter(param, value);
	}

	private callAddFilter(filter: any, id: number, checkbox?: boolean, extra?: any) {
		addFilter(filter, id, checkbox, extra);
	}
}
