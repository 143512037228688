/**
 * Add cookie to browser local store
 */
export const setCookie = (cName, value, exdays, path?) : any => {
	const exdate = new Date();
	exdate.setDate(exdate.getDate() + exdays);
	const cValue = escape(value) + ((exdays == null) ? "" : ("; expires=" + exdate.toUTCString()));
	document.cookie = cName + "=" + cValue + ((path == null) ? "; path=/" : "; path=" + path);
}

/**
 * Get cookie value from browser local store
 */
export const getCookie = (cName, cDefault?) : any => {
	let x: any = document.cookie.split(";");
	let y: any = document.cookie.split(";");
	const ARRcookies: any = document.cookie.split(";");
	// tslint:disable-next-line:prefer-for-of
	for (let i = 0; i < ARRcookies.length; i++) {
		x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
		y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
		x = x.replace(/^\s+|\s+$/g, "");
		if (x === cName) {
			return unescape(y);
		}
	}

	if (typeof cDefault !== "undefined") {
		return cDefault;
	}

	return false;
}