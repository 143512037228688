import get from "lodash/get";
import has from "lodash/has";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { ImageAcf } from "@/data/image_acf";
import { Article } from "@/data/article/article";
import { PersonModel } from "@/data/person/person.model";

export class ArticleModel {
	private namespace!: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.modelName = "posts";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Article> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			)).data;
			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			));
			
			const processedList = data.data.map( entry => {
				return this.processEntry(entry)
			})
			
			return {
				content: processedList,
				total: Number(data.headers["x-wp-total"]),
				totalPages: Number(data.headers["x-wp-totalpages"])
			};
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any, processRelations: boolean = true): Article {
		// console.log("ArticleModel :: processEntry() :: data -> ", data )

		// Null check
		if (!data) {
			return null;
		}

		let categories;
		categories = data.categories.map(category => {
			if (category && data.related_taxonomies) {
				category = data.related_taxonomies[category]
			}
			return category;
		})

		// Process Relations
		let processedRelated;
		if(data.related_ids) {
			if( data.acf.related ) {
				processedRelated = data.acf.related.map(relation => {
					if (relation.acf_fc_layout === "internal_relation") {
						relation.relations = relation.relations.map( id => {
							if (data.related_ids && data.related_ids[id]) {
								return new PersonModel().processEntry(data.related_ids[id])
							}
						})
					}
					return relation;
				})
			}
		}

				// Process Flexible Content
		let processedFlexibleContent = data.acf.flexible_content;
		if (data.acf.flexible_content && data.acf.flexible_content.length) {
			processedFlexibleContent = data.acf.flexible_content.map((line:any) => {
				if (line.acf_fc_layout === "image" && line.images && line.images.length) {
					line.images = line.images.map((image:any) => {
						return new ImageAcf(image);
					})
				}
				if (data.related_ids && line.acf_fc_layout === "relation" && line.relations) {
					line.relations = line.relations.map((id:any) => {
						if (data.related_ids && data.related_ids[id].type === "person") {
							return new PersonModel().processEntry(data.related_ids[id]);
						}
					})
				}
				return line;
			})
		}


		const content: Article = {
			id: data.id,
			title: get(data, "title.rendered"),
			status: data.status,
			slug: data.slug,
			date: data.acf.date ? data.acf.date : data.date,
			
			categories,
			image: data.acf.information.image ? new ImageAcf(data.acf.information.image) : null,
			video: data.acf.information.video,
			headline: data.acf.headline,
			content: data.acf.content,
			information: data.acf.information,
			related: data.acf.related,

			flexibleContent: processedFlexibleContent
		};

		return content;
	}
}
