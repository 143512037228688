// tslint:disable:no-shadowed-variable

import Vue from "@/main";
import router from "@/router";
import i18n from "@/i18n";

import { Getter, State, Mutation, Action } from "vuex-class";
import { getCookie, setCookie } from "@/data/cookie";

// State.
const state = {
	defaultLang: "en",
	availableLangs: ["en", "pt"],
	currentLang: undefined,
	userAgentLang: undefined,
	loadedLangs: []
};

// Getters.
// You can think of them as computed properties for stores. Like computed properties, a getter's result is cached
// based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
const getters = {
	getCurrentLang: (state: any): string => {
		return state.currentLang;
	},
	getCurrentWpmlLang: (state: any): string => {
		return {
			pt: "pt-pt",
			en: null
		}[state.currentLang];
	},
	getAvailableLangs: (state: any): string[] => {
		return state.availableLangs;
	},
	getUserAgentLang: (state: any): string => {
		return state.userAgentLang;
	},
	getLoadedLangs: (state: any): string[] => {
		return state.loadedLangs;
	},
	getDefaultLang: (state: any): string => {
		return state.defaultLang;
	}
};

// Mutations.
// The only way to actually change state in a Vuex store is by committing a mutation.
const mutations = {
	setLocale: (state: any, lang: string): void => {
		state.currentLang = lang;
		i18n.locale = lang;
	},
	setHtmlAttribute: (state: any, lang: string): void => {
		document.querySelector("html")!.setAttribute("lang", lang);
	},
	addLoadedLang: (state: any, lang: string): void => {
		state.loadedLangs.push(lang);
	},
	setUserAgentLang: (state: any): void => {
		state.userAgentLang = window.navigator.language;
	}
};

// Actions are similar to mutations, the differences being that:
// · Instead of mutating the state, actions commit mutations.
// · Actions can contain arbitrary asynchronous operations.
const actions = {
	initModule: ({ state, commit, dispatch }: any): void => {
		commit("setUserAgentLang");

		if (getCookie("lang")) {
			dispatch("changeLanguage", getCookie("lang"));
		} else {
			const routerLang = router.currentRoute.params.lang;
			dispatch("changeLanguage", routerLang || state.defaultLang);
		}
	},
	changeLanguage: ({ state, commit, dispatch }: any, lang: string): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			// Set default language if argumented language isn't available.
			if (!getters.getAvailableLangs(state).includes(lang)) {
				lang = getters.getDefaultLang(state);
			}

			if (getters.getCurrentLang(state) !== lang) {
				dispatch("loadLanguage", lang)
					.then((data: boolean) => {
						if (data !== true) {
							dispatch("setLanguage", lang);
							resolve(true);
						} else {
							reject(false);
						}
					})
					.catch(() => {
						dispatch("setLanguage", getters.getDefaultLang(state));
						reject(false);
					});
			}
			
			dispatch("setLanguage", lang);
			
			setCookie("lang", lang, 365);

			// Update Other Stores on Language Change
			dispatch("menu/getPages", "", { root:true})
			dispatch("core/getContent", "", { root:true})

			resolve(true);
		});
	},
	setLanguage: ({ commit, dispatch }: any, lang: string): void => {
		commit("setLocale", lang);
		commit("setHtmlAttribute", lang);
		dispatch("changeRouterParam", lang);
	},
	changeRouterParam: ({  }: any, lang: string): void => {
		const currentPath = router.currentRoute.fullPath;
		const currentLang = router.currentRoute.params.lang;

		// Evaluate newPath.
		const newPath = currentPath === "/" ? `/${lang}` : currentPath.replace(currentLang, lang);

		router.replace(newPath);
	},
	// BUG: this doesn't seem to be necessary, because the languages already get loaded
	loadLanguage: ({ state, commit }: any, lang: string): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			// Load language by evaluating if language should be loaded.
			if (!getters.getLoadedLangs(state).includes(lang)) {
				return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/${lang}`)
					.then(data => {
						Vue.$i18n.setLocaleMessage(lang, data.default);
						commit("addLoadedLang", lang);
						resolve(true);
					})
					.catch(() => {
						reject(false);
					});
			}
			resolve(true);
		});
	}
};



export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
