import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, State, Mutation } from "vuex-class";

import instance from "@/main";
import { PartnerModel } from "@/data/partner/partner.model";
import { OptionModel } from "@/data/option/option.model";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";

import { addFilter, getFilterObject, getActiveFilter, evalFilter } from "@/data/route";

@Component({
	name: "PartnersPage"
})
export default class PartnersPageComponent extends Vue {
	
	private content: any = null;
	private contentIsReady: boolean = false;
	private sidebar: any = null;
	private partnerTypes: any = null;
	private taxonomiesIsReady: boolean = false;
	private currentPage: number = 1;

	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Mutation("state/setLoading") private setLoading;

	private created() {
		this.getContent();
		this.getTaxonomies();
		this.setLoading(false);
		
		if (!this.$route.query.page) {
			this.$router.push({ query: { page: "1" } })
		} else {
			this.currentPage = Number(this.$route.query.page);
		}
	}

	@Watch("currentLang")
	private async getContent() {
		this.contentIsReady = false;
		this.content = await new PartnerModel().getList({related_recursive_calls: 1, per_page: 100});
		this.contentIsReady = true;
		instance.$emit("PAGE_IS_READY", true);
	}

	private async getTaxonomies() {
		this.taxonomiesIsReady = false;
		this.partnerTypes = await new TaxonomyModel("partner_type").getList({related_recursive_calls: 1});
		this.taxonomiesIsReady = true;
	}
	

	private callEvalFilter(param: string, value: any) {
		return evalFilter(param, value);
	}

	private callAddFilter(filter: any, id: number, checkbox?: boolean, extra?: any) {
		addFilter(filter, id, checkbox, extra);
	}
}
