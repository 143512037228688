import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, State, Mutation } from "vuex-class";

import instance from "@/main";
import { EventModel } from "@/data/event/event.model";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";

import { addFilter, getFilterObject, getActiveFilter, evalFilter } from "@/data/route";

@Component({
	name: "EventsPage"
})
export default class EventsPageComponent extends Vue {
	private waitingForContent: boolean = false;
	private content: any = null;
	private contentIsReady: boolean = false;
	private taxonomiesIsReady: boolean = false;
	private currentPage: number = 1;

	private url: string = window.location.href;

	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@State("i18n") private i18nState!: any;

	@Getter("core/getContent") private cores!: any;
	@Getter("core/hasInitialized") private coreHasInitialized!: any;

	@Getter("eventType/getContent") private types!: any;
	@Getter("eventType/hasInitialized") private typesHasInitialized!: any;

	@Mutation("state/setLoading") private setLoading;

	private mounted() {
		this.setLoading(false);
		if (!this.$route.query.page) {
			this.$router.push({ query: { page: "1" } })
		} else {
			this.currentPage = Number(this.$route.query.page);
		}
		this.getContent();
		this.getTaxonomies();
	}

	@Watch("currentLang")
	@Watch("i18nState.loadedLangs")
	private async getContent() {
		const query = this.$route.query;
		if (this.i18nState.currentLang === this.$route.params.lang) {
			this.contentIsReady = false;
			this.content = await new EventModel().getList({...query });
			this.contentIsReady = true;
			this.currentPage = Number(this.$route.query.page);
			instance.$emit("PAGE_IS_READY", true);
		}
	}

	@Watch("coreHasInitialized")
	@Watch("typesHasInitialized")
	private getTaxonomies() {
		this.taxonomiesIsReady = this.coreHasInitialized && this.typesHasInitialized ? true : false;
	}
	
	@Watch("$route.query")
	private async updateContent() {
		const query = this.$route.query;
		if (this.i18nState.currentLang === this.$route.params.lang) {
			this.contentIsReady = false;
			this.content = await new EventModel().getList({ related_recursive_calls: 1, ...query });
			this.contentIsReady = true;
			this.currentPage = Number(this.$route.query.page);
			instance.$emit("PAGE_IS_READY", true);
		}
	}
	

	private callEvalFilter(param: string, value: any) {
		return evalFilter(param, value);
	}

	private callAddFilter(filter: any, id: number, checkbox?: boolean, extra?: any) {
		addFilter(filter, id, checkbox, extra);
	}
}
