import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { Image } from "@/data/image";

@Component({
	name: "HeroBlock"
})
export default class CardBlockComponent extends Vue {
	@Prop() private images!: Image[];

	private hasScrolled: boolean = false;
	private hasScroll: boolean = true;

	private evalScroll(el) {
		const gallery = el.srcElement;
		// Has Scrolled
		gallery.scrollLeft > 0 ? (this.hasScrolled = true) : (this.hasScrolled = false);
		// Remaining Scroll
		gallery.scrollWidth - (gallery.scrollLeft + gallery.offsetWidth) === 0
			? (this.hasScroll = false)
			: (this.hasScroll = true);
	}

	private scrollMove(el, positive) {
		const gallery = el.srcElement.parentElement.parentElement.nextSibling;
		const imageWidth = gallery.children[1].offsetWidth;

		const scrolled = gallery.scrollLeft;

		if (gallery && positive) {
			gallery.scroll({ top: 0, left: scrolled + imageWidth, behavior: "smooth" });
		} else if (gallery && !positive) {
			gallery.scroll({ top: 0, left: scrolled - imageWidth, behavior: "smooth" });
		}
	}
}
