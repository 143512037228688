// tslint:disable:no-shadowed-variable

import Vue from "@/main";
import router from "@/router";
import { PageModel } from "@/data/page/page.model";

// State.
const state = {
	content: {
		hidden: []
	},
	hasInitialized: false
};

// Getters.
// You can think of them as computed properties for stores. Like computed properties, a getter's result is cached
// based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
const getters = {
	getContent: (state: any): any => {
		return state.content;
	},
	hasInitialized: (state: any): boolean => {
		return state.hasInitialized;
	},
};

// Mutations.
// The only way to actually change state in a Vuex store is by committing a mutation.
const mutations = {
	setContent: (state: any, pagesData: any): void => {
		// for (const page of pagesData.content) {
		// 	if (page.menuPosition) {
		// 		state.content[page.menuPosition].push(page);
		// 	} else {
		// 		state.content.hidden.push(page);
		// 	}
		// }
		state.content = pagesData;
	},
	finishedInitialization: (state: any): void => {
		state.hasInitialized = true;
	}
};

// Actions are similar to mutations, the differences being that:
// · Instead of mutating the state, actions commit mutations.
// · Actions can contain arbitrary asynchronous operations.
const actions = {
	initModule: async ({ state, commit, dispatch }: any): Promise<void> => {
		await dispatch("getPages");
	},
	getPages: async ({ state, commit, dispatch }: any): Promise<void> => {
		const content: any = [];
		// API Request of all Taxonomies
		
		const pageData = await new PageModel().getList({
			per_page: 20
		});
		// Set Content
		commit("setContent", pageData);
		// Finish
		commit("finishedInitialization");

		return Promise.resolve();
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
