// tslint:disable:no-shadowed-variable

import router from "@/router";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";
import { Taxonomy } from "@/data/taxonomy/taxonomy";

// State.
const state = {
	initialized: false,
	content: null
};

// Getters.
// You can think of them as computed properties for stores. Like computed properties, a getter's result is cached
// based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
const getters = {
	getContent: (state: any): Taxonomy => {
		return state.content;
	},
	hasInitialized: (state: any): boolean => {
		return state.initialized;
	}
};

// Mutations.
// The only way to actually change state in a Vuex store is by committing a mutation.
const mutations = {
	finishedInitialization: (state: any): void => {
		state.initialized = true;
	},
	setContent: (state: any, content: Taxonomy[]): void => {
		state.content = content;
	},
};

// Actions are similar to mutations, the differences being that:
// · Instead of mutating the state, actions commit mutations.
// · Actions can contain arbitrary asynchronous operations.
const actions = {
	initModule: async ({ state, commit, dispatch }: any): Promise<void> => {
		// Get content
		await Promise.all([dispatch("getContent")]);

		// Finish
		commit("finishedInitialization");
	},
	getContent: async ({ state, commit, dispatch }: any): Promise<void> => {
		const content = await new TaxonomyModel("event_type").getList();
		commit("setContent", content);
		return Promise.resolve();
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
