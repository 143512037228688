import { Vue, Component, Prop, Model, Watch, Emit, Provide } from "vue-property-decorator";
import { State, Getter, Action, Mutation } from "vuex-class";

import instance from "@/main";
import store from "@/store";
import { transform } from "lodash";

@Component({})
export default class HeaderBlockComponent extends Vue {
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;
	
	@Getter("state/getLoading") private isLoading: boolean;

	@Prop() private link : any;

	private scrollAmount : number = window.pageYOffset;

	private colors : any = [
		"#23E84E",
		"#EC11FF",
		"#1185FF",
		"#FF5823",
		"#8c21FF",
		"#FF001F",
		"#FFD61F",
		"#4FD1FA",
		"#2CEDCA",
	]

	private created() {
		window.addEventListener("scroll", () => {
			this.scrollAmount = window.pageYOffset;
		})
		this.getRandomColor();
	}

	@Watch("$route")
	private getRandomColor() {
		const random = Math.floor(Math.random() * this.colors.length)
		const body = document.querySelector("body") as HTMLElement;
		const randomColor = this.colors[random];
		body.setAttribute("style", "--randomColor: " + randomColor)
		body.setAttribute("data-random-color", randomColor)
	}

	get isSmall(): boolean {
		if (this.$route.name === "MapPage") {
			return true;
		} else {
			return false;
		}
	}
}

