import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, State } from "vuex-class";

import instance from "@/main";
import { ArticleModel } from "@/data/article/article.model";
import { scrollTo } from "@/scrollTo";


@Component({
	name: "ArticlePage"
})
export default class ArticlePageComponent extends Vue {
	private contentIsReady: boolean = false;
	private waitingForContent: boolean = false;
	private sidebarIsVisible: boolean = true;
	private content: any = null;

	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@State("i18n") private i18nState!: any;

	private mounted() {
		this.getContent();
	}

	@Watch("currentLang")
	@Watch("$route.params.slug")
	@Watch("i18nState.loadedLangs")
	private async getContent() {
		if (this.i18nState.currentLang === this.$route.params.lang) {
			this.contentIsReady = false;
			const slug = this.$route.params.slug;
			this.content = await new ArticleModel().getEntry({slug, related_recursive_calls: 1});
			instance.$emit("PAGE_IS_READY", true);
			this.contentIsReady = true;
		}
	}
}
