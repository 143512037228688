export function scrollTo(id: string, offset?: number | string, offsetType?: "height" | "distance") {
	// - Calculate the offset value based on the argument provided
	let offsetValue = 0;
	if (offset && typeof offset === "number" ) {
		offsetValue = offset;
	} 
	if (offset && typeof offset === "string") {
		const element = document.getElementById(offset) as HTMLElement; 
		offsetType === "distance" ? offsetValue = (element.offsetTop - 12) : offsetValue = element.offsetHeight;
	} 
	
	// - Find the position of the element by the ID
	const el = document.getElementById(id) as HTMLElement;
	window.scroll({
		top: el.offsetTop - (offsetValue),
		left: 0,
		behavior: "smooth"
	});
}