import { Vue, Component, Prop, Model, Watch, Emit, Provide } from "vue-property-decorator";
import { State, Getter, Action, Mutation } from "vuex-class";

import instance from "@/main";
import store from "@/store";
import { PageModel } from "@/data/page/page.model";

@Component({})
export default class HeaderBlockComponent extends Vue {
	@Getter("menu/getContent") private pages!: string;
	@Getter("menu/hasInitialized") private menuHasInitialized!: boolean;

	@State("i18n") private i18nState!: any;
	@Provide() private content: any = null;
	@Provide() private contentIsReady: boolean = false;
	@Provide() private menuIsOpen: boolean = false;

	private questionIterator: number = 0;

	get hasSections(): boolean {
		if (this.$route.name === "EventPage" || this.$route.name === "ArticlePage" || this.$route.name === "SamplePage") {
			return true;
		} else {
			return false;
		}
	}

	@Watch("menuHasInitialized")
	private async getContent() {
		if (this.menuHasInitialized) {
			this.contentIsReady = true;
		}
	}
}
