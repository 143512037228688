import Vue from "vue";
import Vuex from "vuex";

import i18n from "@/stores/i18n.store";
import menu from "@/stores/menu.store";
import edition from "@/stores/edition.store";
import state from "@/stores/state.store";
import core from "@/stores/core.store";
import eventType from "@/stores/event-type.store";
import location from "@/stores/location.store";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		i18n,
		menu,
		edition,
		state,
		core,
		eventType,
		location
	},
	strict: process.env.NODE_ENV !== "production"
});
