import { Vue, Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { OptionModel } from "@/data/option/option.model";

@Component({})
export default class FooterBlockComponent extends Vue {
	@Getter("menu/getContent") private pages!: string;
	@Getter("menu/hasInitialized") private menuHasInitialized!: boolean;

	private content: any = null;
	private contentIsReady: boolean = false;


	private created() {
		this.getContent();
	}
	
	private async getContent() {
		this.content = await new OptionModel("footer-options").getEntry();
		this.contentIsReady = true;
	}

	get isMap() {
		return this.$route.name === "MapPage" ? true : false;
	}
}
