import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

import instance from "@/main";
import { PageModel } from "@/data/page/page.model";
import { scrollTo } from "@/scrollTo";


@Component({
	name: "SamplePage"
})
export default class SamplePageComponent extends Vue {
	private contentIsReady: boolean = false;
	private waitingForContent: boolean = false;
	private content: any = null;
	private sidebarIsVisible: boolean = true;
	private sectionIsActive: string = "";

	private url: string = window.location.href;

	@Getter("i18n/getCurrentLang") private currentLang!: string;

	private created() {
		this.getContent();
		this.$router.replace({"query": null});
	}

	private mounted() {
		window.addEventListener("scroll", () => {
			this.checkSidebarVisibility();
			this.checkPosition();
		})
	}

	@Watch("currentLang")
	@Watch("$route.params.slug")
	private async getContent() {
		const slug = this.$route.params.slug;
		this.contentIsReady = false;
		this.content = await new PageModel().getEntry({ slug, related_recursive_calls: 1 });
		instance.$emit("PAGE_IS_READY", true);
		this.contentIsReady = true;
	}

	private callScrollTo(id, offset, offsetType) {
		scrollTo(id, offset, offsetType);
	}

	private checkPosition() {
		const sidebar = document.querySelector("#sidebar-block");
		const sections = document.getElementsByClassName("section");

		if (sidebar) {
			const amountScrolled = window.scrollY + sidebar.getBoundingClientRect().top;
			for (const sec of sections) {
				const section = sec as HTMLElement;
				if ( amountScrolled > section.offsetTop ) {
					this.sectionIsActive = section.id;
				}
			}
		}
	}

	private checkSidebarVisibility() {
		const sidebar = document.getElementById("sections-nav") as HTMLElement;
		// const header = document.querySelector("#project-header") as HTMLElement;

		const overflowElements = document.getElementsByClassName("sidebar-overflow") as HTMLCollection;

		
		if (sidebar) {
			const offset = window.scrollY + sidebar.getBoundingClientRect().top;
			this.sidebarIsVisible = true;
			
			for (const el of overflowElements) {
				const element = el as HTMLElement;
				if (offset + sidebar.offsetHeight > element.offsetTop && offset < element.offsetTop + element.offsetHeight ) {
					this.sidebarIsVisible = false;
				}
			}
		}
	}
}
