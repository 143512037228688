import { Vue, Component, Prop, Model, Watch, Emit, Provide } from "vue-property-decorator";
import { State, Getter, Action, Mutation } from "vuex-class";

import instance from "@/main";
import store from "@/store";
import { transform } from "lodash";

@Component({})
export default class CloudBlockComponent extends Vue {
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Prop() private link!: any;
	
	@Prop() private seconds!: number;
	@Prop() private strokeWidth!: number;
	@Prop() private color!: string;
	@Prop() private id!: number;
	@Prop() private image!: any;
	@Prop() private radius!: number;
	@Prop() private position!: string;

	private margin: number = 0;
	private columns: number = 0;
	private rows: number = 0;

	private colors : any = [
		"#11FF45",
		"#EC11FF",
		"#1185FF",
		"#FF4A11",
		"#8c21FF",
		
	]

	private mounted() {
		const rootElement = this.$el as HTMLElement;
		const parentElement = rootElement.parentElement.parentElement;
		
		if (this.color === "random") {
			const random = Math.floor(Math.random() * this.colors.length)
			const randomColor = this.colors[random];
			rootElement.setAttribute("style", "--randomColor: " + randomColor + "; --strokeWidth: " + this.strokeWidth + ";");
		} else {
			rootElement.setAttribute("style", "--strokeWidth: " + this.strokeWidth + ";");
		}

		if (this.position === "round" && window.innerWidth > 480) {

			const parentX = parentElement.offsetLeft + parentElement.offsetWidth / 2;
			const rootX = rootElement.offsetLeft + rootElement.offsetWidth / 2;
			const differenceX =  rootX - parentX;
			// Vertical
			const parentY = parentElement.offsetTop + parentElement.offsetHeight / 2;
			const rootY = rootElement.offsetTop + rootElement.offsetHeight / 2;
			const differenceY =  rootY - parentY;
			
			// console.log("parentElement: ", parentElement)
			// console.log("rootElement: ", rootElement)
			// console.log("horizontalDifference : ", differenceX);
			if (differenceX > 0 && differenceY < 0) {
				rootElement.style.transform = "translate(0, " + differenceX / 4 + "px)";
			} else if (differenceX > 0 && differenceY > 0) {
				rootElement.style.transform = "translate(0, " + -differenceX / 4 + "px)";
			} else if (differenceX < 0 && differenceY < 0) {
				rootElement.style.transform = "translate(0, " + -differenceX / 4 + "px)";
			} else if (differenceX < 0 && differenceY > 0) {
				rootElement.style.transform = "translate(0, " + differenceX / 4 + "px)";
			}
		}

		this.animateCircles();
		setInterval(() => {
			this.animateCircles();
		}, this.seconds * 1000);

		this.checkCloudGrid();
		setInterval(() => {
			this.checkCloudGrid();
		}, this.seconds * 10)
	}

	private checkCloudGrid(){
		const rootElement = this.$el as HTMLElement;
		this.margin = this.radius / 6;
		this.columns = Math.floor((rootElement.offsetWidth - this.margin) / this.radius);
		this.rows = Math.round((rootElement.offsetHeight - this.margin) / this.radius);
		this.rows = this.rows === 0 ? 1 : this.rows;
	}

	private animateCircles() {
		const rootElement = this.$el as HTMLElement;
		const strokeCircles = rootElement.getElementsByClassName("cloud-stroke")
		const fillCircles = rootElement.getElementsByClassName("cloud-fill")
		const maskCircles = rootElement.getElementsByClassName("cloud-mask")
		
		for (let i = 0; i < strokeCircles.length; i++) {
			const strokeCircle = strokeCircles[i] as HTMLElement;
			const fillCircle = fillCircles[i] as HTMLElement;
			const maskCircle = maskCircles[i] as HTMLElement;
			
			const randomX = Math.floor(Math.random() * this.radius / 2 - this.radius / 4)
			const randomY = Math.floor(Math.random() * this.radius / 2 - this.radius / 4)
			
			strokeCircle.setAttribute("style", "transform: translate(" + randomX + "px ," + randomY + "px )")
			fillCircle.setAttribute("style", "transform: translate(" + randomX + "px ," + randomY + "px )")
			maskCircle.setAttribute("style", "transform: translate(" + randomX + "px ," + randomY + "px )")
		}
	}
}

