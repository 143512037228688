import get from "lodash/get";
import has from "lodash/has";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { ImageAcf } from "@/data/image_acf";
import { File } from "@/data/file";
// import { Project } from "@/data/project/project";
import { Partner } from "@/data/partner/partner";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";

export class PartnerModel {
	private namespace!: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.modelName = "partners";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Partner> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, related_recursive_calls: 3, ...queries })
			)).data;
			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		this.namespace = "wp/v2";
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			));
			
			const processedList = data.data.map( entry => {
				return this.processEntry(entry)
			})
			
			return {
				content: processedList,
				total: Number(data.headers["x-wp-total"]),
				totalPages: Number(data.headers["x-wp-totalpages"])
			};
		} catch (error) {

			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any, processRelations: boolean = true): Partner {
		console.log("PartnerModel :: processEntry : data", data);

		// Null check
		if (!data) {
			return null;
		}

		let processedTypes;
		if(data.related_taxonomies) {
			if( data.partner_type && data.partner_type.length ) {
				processedTypes = data.partner_type.map(id => {
					if (data.related_taxonomies) {
						return new TaxonomyModel("partner_type").processEntry(data.related_taxonomies[id])
					}
				})
			}
		}
		
		const content: Partner = {
			id: data.id,
			title: get(data, "title.rendered"),
			slug: data.slug,
			
			partnerType: processedTypes,
			logo: data.acf.logo ? new File(data.acf.logo) : null,
			contacts: data.acf.contacts,
			description: data.acf.description,
		};

		return content;
	}
}
