import { render, staticRenderFns } from "./persons.page.pug?vue&type=template&id=761a3a12&lang=pug&"
import script from "./persons.page.ts?vue&type=script&lang=ts&"
export * from "./persons.page.ts?vue&type=script&lang=ts&"
import style0 from "./persons.page.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports