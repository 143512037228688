import get from "lodash/get";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Taxonomy } from "@/data/taxonomy/taxonomy";
import { Image } from "@/data/image";

export class TaxonomyModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor(taxonomy: string) {
		this.namespace = "wp/v2";
		this.modelName = taxonomy;
		this.api = new WpApi();
	}

	/**
	 * Returns a single Taxonomy.
	 */
	public async getEntry(id: number): Promise<Taxonomy> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		console.log("ID : ", id);

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ id }));

			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of Taxnomies.
	 */
	public async getList(queries?: Query): Promise<any> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ ...queries, lang, related_recursive_calls: 1 })
			);

			const processedList = data.data.map( entry => {
				return this.processEntry(entry)
			})
			
			return {
				content: processedList,
				total: Number(data.headers["x-wp-total"]),
				totalPages: Number(data.headers["x-wp-totalpages"])
			};
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any, processRelations: boolean = true): Taxonomy {
		// console.log("TaxonomyModel :: processEntry : data", data);

		const content: Taxonomy = {
			id: data.id,
			slug: data.slug,
			name: data.name,
			count: data.count,
			parent: data.parent || null,
			children: data.children || null
		};

		return content;
	}
}
