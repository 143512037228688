import { Vue, Component, Prop, Model, Watch, Emit, Provide } from "vue-property-decorator";

@Component({})
export default class CardBlockComponent extends Vue {
	@Prop() private color: string;
	@Prop() private image: boolean;

	private randomNumber : number;

	private created() {
		this.randomNumber = Math.floor(Math.random() * Math.floor(5))
	}

	@Watch("route") 
	private changeRandom() {
		this.randomNumber = Math.floor(Math.random() * Math.floor(5))
	}
}
