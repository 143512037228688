import { Vue, Component, Prop, Model, Watch, Emit, Provide } from "vue-property-decorator";
import { Getter, State, Mutation } from "vuex-class";


@Component({})
export default class ListBlockComponent extends Vue {
	@Prop() private size: string;
	@Prop() private link: any;
	@Prop() private entry: any;

	@Getter("location/getContent") private locations!: any;
	@Getter("location/hasInitialized") private locationHasInitialized!: any;

	@Getter("eventType/getContent") private types!: any;
	@Getter("eventType/hasInitialized") private typesHasInitialized!: any;


	private getLocationObject(id) {
		return this.locations.content.find( c => c.id === id);
	}

	private getTypeObject(id) {
		return this.types.content.find( c => c.id === id) || null;
	}
}
