import { Vue, Component, Prop, Model, Watch, Emit, Provide } from "vue-property-decorator";
import { State, Getter, Action, Mutation } from "vuex-class";

import instance from "@/main";
import store from "@/store";
import { PageModel } from "@/data/page/page.model";

@Component({})
export default class HeaderBlockComponent extends Vue {
	@Getter("menu/getContent") private pages!: string;
	@Getter("menu/hasInitialized") private menuHasInitialized!: boolean;

	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;


	@State("i18n") private i18nState!: string[];
	@Provide() private content: any = null;
	@Provide() private contentIsReady: boolean = false;
	@Provide() private menuIsOpen: boolean = false;

	private questionIterator: number = 0;

	private cleanMenuState() {
		this.toggleMenu(false);
		(document.activeElement as HTMLElement).blur();
	}

	private created() {
		this.getContent();
	}

	@Watch("menuHasInitialized")
	private async getContent() {
		if (this.menuHasInitialized) {
			this.contentIsReady = true;
		}
	}

	private toggleMenu(force?: boolean): void {
		this.menuIsOpen = force !== undefined ? force : !this.menuIsOpen;
		document.body.classList.toggle("no--scroll", force);
	}

	/**
	 * Polyfill to add ':focus-within' capabilities via JavaScript.
	 */
	private setFocusWithin(el: HTMLElement) {
		const focusWithinClass = "js-focus-within";

		// tslint:disable-next-line:no-shadowed-variable
		Array.from(document.getElementsByClassName(focusWithinClass)).forEach((el: Element) => {
			el.classList.remove(focusWithinClass);
		});

		el.classList.add(focusWithinClass);
	}

	get isTransparent(): boolean {
		return this.$route.name === "MapPage" ? true : false;
	}

	get pageName(): any {
		return this.$route.name;
	}

	get hasTitle(): any {
		return this.$route.name === "HomePage" || this.$route.name === "SamplePage" || this.$route.name === "EventPage" || this.$route.name === "ArticlePage";
	}
}

