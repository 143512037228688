import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, State } from "vuex-class";

import instance from "@/main";
import { PressModel } from "@/data/press/press.model";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";

import { addFilter, getFilterObject, getActiveFilter, evalFilter } from "@/data/route";

@Component({
	name: "PressPage"
})
export default class PressPageComponent extends Vue {
	private contentIsReady: boolean = false;
	private waitingForContent: boolean = false;
	private content: any = null;
	private pressTypes: any = null;

	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@State("i18n") private i18nState!: any;

	private created() {
		this.getContent();
	}

	@Watch("currentLang")
	@Watch("i18nState.loadedLangs")
	private async getContent() {
		if (this.i18nState.currentLang === this.$route.params.lang) {
		this.contentIsReady = false;
			this.content = await new PressModel().getList({related_recursive_calls: 1, per_page: 100});
		this.contentIsReady = true;
		instance.$emit("PAGE_IS_READY", true);
		}
	}

	@Watch("$route.query")
	private async getMoreContent() {
		const pressType = this.$route.query.press_type;
		this.content = await new PressModel().getList({ related_recursive_calls: 1, press_type: pressType });
	}

	private callEvalFilter(param: string, value: any) {
		return evalFilter(param, value);
	}

	private callAddFilter(filter: any, id: number, checkbox?: boolean, extra?: any) {
		addFilter(filter, id, checkbox, extra);
	}
}
