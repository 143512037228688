import get from "lodash/get";
import has from "lodash/has";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { ImageAcf } from "@/data/image_acf";
import { Location } from "@/data/location/location";
import { EventModel } from "@/data/event/event.model";

export class LocationModel {
	private namespace!: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.modelName = "locations";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Location> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			)).data;
			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			));
			
			const processedList = data.data.map( entry => {
				return this.processEntry(entry)
			})
			
			return {
				content: processedList,
				total: Number(data.headers["x-wp-total"]),
				totalPages: Number(data.headers["x-wp-totalpages"])
			};
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any, processRelations: boolean = true): Location {
		// console.log("LocationModel :: processEntry() :: data -> ", data )

		// Null check
		if (!data) {
			return null;
		}

		
		let processedEditions;
		if(data.related_taxonomies) {
			if( data.edition && data.edition.length ) {
				processedEditions = data.edition.map(id => {
					if (id && data.related_taxonomies) {
						return data.related_taxonomies[id]
					}
				})
			}
		}

		let processedEvents;
		if(data.related_ids) {
			if( data.acf.related_events ) {
				processedEvents = data.acf.related_events.map(id => {
					if (data.related_ids && data.related_ids[id]) {
						return new EventModel().processEntry(data.related_ids[id])
					}
				})
			}
		}

		const content: Location = {
			id: data.id,
			title: get(data, "title.rendered"),
			slug: data.slug,
			status: data.status,
			
			editions: processedEditions,
			size: data.acf.size,
			image: data.acf.image ? new ImageAcf(data.acf.image) : null,
			map: data.acf.map,
			description: data.acf.description,
			
			relatedEvents: processedEvents || null,
		};

		return content;
	}
}
