import { Vue, Component, Prop, Model, Watch, Emit, Provide } from "vue-property-decorator";

@Component({})
export default class CuratorBlockComponent extends Vue {
	@Prop() private person: any;
	@Prop() private link: any;

	private biographyIsLarge : boolean = false;
	private biographyIsOpen : boolean = false;
	
	private mounted(){
		console.log("Biography length:", this.person.shortBiography.length)
		if (this.person.shortBiography.length > 480){
			this.biographyIsLarge = true;
		}

		const biography = this.$el.querySelector(".curator-block--bio")
		const bioHeight = biography.scrollHeight

		this.$el.setAttribute("style", "--scrollHeight: " + bioHeight + "px");
	}
}
