import get from "lodash/get";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";

import { ImageAcf } from "@/data/image_acf";

export class OptionModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor(option: string) {
		this.namespace = "custom/settings";
		this.modelName = option;
		this.api = new WpApi();
	}

	/**
	 * Returns a single Taxonomy.
	 */
	public async getEntry(queries? : Query): Promise<any> {
		const lang = store.getters["i18n/getCurrentLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, queries);
			return data.data;
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any, processRelations: boolean = true): any {
		const content: any = {
			title: data.title,
			link: data.link,
			video: data.acf.video,
			content: data.acf.content
		};
		return content;
	}
}
