import get from "lodash/get";
import has from "lodash/has";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Page } from "@/data/page/page";
import { Image } from "@/data/image";
import { ImageAcf } from "@/data/image_acf";

import { PersonModel } from "@/data/person/person.model";

export class PageModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wp/v2";
		this.modelName = "pages";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries: Query): Promise<Page> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];
		try {
			const data = (await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ lang, ...queries }))).data;

			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];
		// console.log("PageModel :: getList :: lang -> ", lang)

		try {
			const data = (await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ lang, ...queries })));

			const processedList = data.data.map(entry => {
				return this.processEntry(entry)
			})

			return {
				content: processedList,
				total: Number(data.headers["x-wp-total"]),
				totalPages: Number(data.headers["x-wp-totalpages"])
			};
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a Pages.
	 */
	public async getOrderedPage(id: number): Promise<any> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(`wp/v2/ordered-pages`, new Query({ lang, id })));

			return this.processEntry(data.data);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getOrderedPages(id?: number): Promise<any> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(`wp/v2/ordered-pages`, new Query({ lang, id })));

			const processedList = data.data.map(entry => {
				return this.processEntry(entry)
			})

			return processedList;
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any): Page {

		// Null check
		if (!data) {
			return null;
		}

		// Process ChildPages
		let childPages;
		if(data.child_pages) {
			childPages = data.child_pages.map( page => {
				return this.processEntry(page)
			})
		}

		// Process Flexible Content
		let processedFlexibleContent = data.acf.flexible_content;
		if (data.acf.flexible_content.length) {
			processedFlexibleContent = data.acf.flexible_content.map((line:any) => {
				if (line.acf_fc_layout === "image" && line.images.length) {
					line.images = line.images.map((image:any) => {
						return new ImageAcf(image);
					})
				}
				if (data.related_ids && line.acf_fc_layout === "relation" && line.relations) {
					line.relations = line.relations.map((id:any) => {
						if (data.related_ids && data.related_ids[id].type === "person") {
							return new PersonModel().processEntry(data.related_ids[id]);
						}
					})
				}
				return line;
			})
		}

		const content: Page = {
			id: data.id,
			slug: data.slug,
			title: get(data, "title.rendered"),
			parent: get(data, "parent"),
			childPages,
			
			fullTitle: data.acf.full_title,
			menu: data.acf.menu,
			image: data.acf.image,
			description: data.acf.description,
			flexibleContent: data.acf.flexible_content
		};

		return content;
	}
}
