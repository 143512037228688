export default function(element: HTMLElement): number {
	// Null check
	if (!element) {
		return null;
	}

	const height = element.offsetHeight;

	// tslint:disable-next-line:radix
	const lineHeight = parseInt(document.defaultView.getComputedStyle(element, null).getPropertyValue("line-height"));

	const lines = Math.round(height / lineHeight);

	return lines;
}
