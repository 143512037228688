// tslint:disable:no-shadowed-variable

// State.
const state = {
	isLoadingFull: true,
	isLoading: false
};

// Getters.
// You can think of them as computed properties for stores. Like computed properties, a getter's result is cached
// based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
const getters = {
	getLoadingFull: (state: any): boolean => {
		return state.isLoadingFull;
	},
	getLoading: (state: any): boolean => {
		return state.isLoading;
	}
};

// Mutations.
// The only way to actually change state in a Vuex store is by committing a mutation.
const mutations = {
	setLoadingFull: (state: any, payload: boolean): void => {
		state.isLoadingFull = payload;
	},
	setLoading: (state: any, payload: boolean): void => {
		state.isLoading = payload;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations
};
