import get from "lodash/get";
import has from "lodash/has";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { ImageAcf } from "@/data/image_acf";
import { Event } from "@/data/event/event";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";
import { LocationModel } from "@/data/location/location.model";
import { PersonModel } from "@/data/person/person.model";
import { ArticleModel } from "@/data/article/article.model";

export class EventModel {
	private namespace!: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.modelName = "events";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Event> {
		this.namespace = "wp/v2";

		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			)).data;
			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		this.namespace = "wp/v2";
		
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = (await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ lang, ...queries })
			));
			
			const processedList = data.data.map( entry => {
				return this.processEntry(entry)
			})
			
			return {
				content: processedList,
				total: Number(data.headers["x-wp-total"]),
				totalPages: Number(data.headers["x-wp-totalpages"])
			};
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any, processRelations: boolean = true): Event {
		// console.log("EventModel :: processEntry() :: data -> ", data )

		// Null check
		if (!data) {
			return null;
		}

		let processedEventTypes = null;
		let processedEditions = null;
		let processedCores = null;
		if(data.related_taxonomies) {
			if( data.event_type && data.event_type.length ) {
				processedEventTypes = data.event_type.map(id => {
					if (data.related_taxonomies) {
						return new TaxonomyModel("event_type").processEntry(data.related_taxonomies[id])
					}
				})
			}
			if( data.edition && data.edition.length ) {
				processedEditions = data.edition.map(id => {
					if (data.related_taxonomies) {
						return new TaxonomyModel("edition").processEntry(data.related_taxonomies[id])
					}
				})
			}
			if( data.core && data.core.length ) {
				processedCores = data.core.map(id => {
					if (data.related_taxonomies) {
						return new TaxonomyModel("core").processEntry(data.related_taxonomies[id])
					}
				})
			}
		}

		let processedLocations;
		let processedNews;
		let processedPersons;
		if(data.related_ids) {
			if( data.acf.relations.locations ) {
				processedLocations = data.acf.relations.locations.map(id => {
					if (data.related_ids && data.related_ids[id]) {
						return new LocationModel().processEntry(data.related_ids[id])
					}
				})
			}
			if( data.acf.relations.news ) {
				processedNews = data.acf.relations.news.map(id => {
					if (data.related_ids && data.related_ids[id]) {
						return new ArticleModel().processEntry(data.related_ids[id])
					}
				})
			}
			if( data.acf.persons ) {
				processedPersons = data.acf.persons.map(entry => {
					if (entry.type === "person" && entry.persons) {
						entry.persons = entry.persons.map( (id:number) => {
							return new PersonModel().processEntry(data.related_ids[id])
						})
					} 
					return entry;
				})
			}
		}

		// Process Flexible Content
		let processedFlexibleContent = data.acf.flexible_content;
		if (data.acf.flexible_content && data.acf.flexible_content.length) {
			processedFlexibleContent = data.acf.flexible_content.map((line:any) => {
				if (line.acf_fc_layout === "image" && line.images && line.images.length) {
					line.images = line.images.map((image:any) => {
						return new ImageAcf(image);
					})
				}
				if (data.related_ids && line.acf_fc_layout === "relation" && line.relations) {
					line.relations = line.relations.map((id:any) => {
						if (data.related_ids && data.related_ids[id].type === "person") {
							return new PersonModel().processEntry(data.related_ids[id]);
						}
					})
				}
				return line;
			})
		}

		const content: Event = {
			id: data.id,
			title: get(data, "title.rendered"),
			slug: data.slug,
			status: data.status,
			
			weekActive: data.acf.schedule.week_active,
			dateStart: data.acf.schedule.start_date,
			dateEnd: data.acf.schedule.end_date,
			dateVisible: data.acf.schedule.time_visibility,
			image: data.acf.media.image ? new ImageAcf(data.acf.media.image) : null,
			video: data.acf.media.video,
			description: data.acf.description,
			
			links: data.acf.links,
			
			flexibleContent: processedFlexibleContent,
			
			locations: processedLocations || data.acf.relations.locations,
			news: processedNews || null,
			persons: processedPersons || null,

			eventTypes: processedEventTypes || data.event_type,
			cores: processedCores || data.core,
			editions: processedEditions || null,
		};

		return content;
	}
}
