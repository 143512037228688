import { Vue, Component, Prop, Model, Watch, Emit, Provide } from "vue-property-decorator";

@Component({})
export default class CardBlockComponent extends Vue {
	@Prop() private entry: any;
	@Prop() private link: any;
	@Prop() private aspect: string;
	@Prop() private size: string;
	@Prop() private image: boolean;
}
